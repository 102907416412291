const colors = {
  // link colors
  link: {
    dark: 'text-gray-700 data-[hovered=true]:text-yellow-500',
    light: 'text-white data-[hovered=true]:text-yellow-300',
    gray: 'text-gray-400 data-[hovered=true]:text-gray-500',
    primary: 'text-yellow-500 data-[hovered=true]:text-yellow-300',
    whatsapp: 'text-whatsapp-500 data-[hovered=true]:text-whatsapp-600'
  },
  // solid colors
  solid: {
    dark: 'border-gray-800 data-[hovered=true]:border-gray-900 bg-gray-800 data-[hovered=true]:bg-gray-900 text-white',
    light:
      'border-white text-gray-900 data-[hovered=true]:border-blue-500 bg-white data-[hovered=true]:bg-blue-500 data-[hovered=true]:text-white',
    gray: '',
    primary:
      'border-yellow-500 data-[hovered=true]:border-green-700 bg-yellow-500 data-[hovered=true]:bg-green-700 text-white',
    whatsapp:
      'border-whatsapp-500 data-[hovered=true]:border-whatsapp-600 bg-whatsapp-500 data-[hovered=true]:bg-whatsapp-600 text-white'
  },
  // outline colors
  outline: {
    dark: 'border-gray-800 data-[hovered=true]:bg-gray-800 text-gray-800 data-[hovered=true]:text-white',
    light:
      'border-white data-[hovered=true]:bg-white text-white data-[hovered=true]:text-gray-800',
    gray: '',
    primary:
      'border-yellow-500 data-[hovered=true]:border-yellow-700 data-[hovered=true]:bg-yellow-700 text-yellow-500 data-[hovered=true]:text-yellow-50',
    whatsapp:
      'border-whatsapp-500 data-[hovered=true]:bg-whatsapp-500 text-whatsapp-500 data-[hovered=true]:text-white'
  },
  // ghost colors
  ghost: {
    dark: 'data-[hovered=true]:border-gray-50 data-[hovered=true]:bg-gray-50 text-gray-800',
    light:
      'data-[hovered=true]:border-gray-800/5 data-[hovered=true]:bg-gray-800/5 text-white data-[hovered=true]:text-gray-800',
    gray: '',
    primary:
      'data-[hovered=true]:border-blue-500/5 data-[hovered=true]:bg-blue-500/5 text-blue-500',
    whatsapp:
      'data-[hovered=true]:border-whatsapp-500/5 data-[hovered=true]:bg-whatsapp-500/5 text-whatsapp-500'
  }
}

export default colors
