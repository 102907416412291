import { ThemeConfig } from 'tailwindcss/types/config'

const backgroundImage = {
  ['none']: 'none',
  ['gradient-to-t']: `linear-gradient(to top, var(--tw-gradient-stops))`,
  ['gradient-to-tr']: `linear-gradient(to top right, var(--tw-gradient-stops))`,
  ['gradient-to-r']: `linear-gradient(to right, var(--tw-gradient-stops))`,
  ['gradient-to-br']: `linear-gradient(to bottom right, var(--tw-gradient-stops))`,
  ['gradient-to-b']: `linear-gradient(to bottom, var(--tw-gradient-stops))`,
  ['gradient-to-bl']: `linear-gradient(to bottom left, var(--tw-gradient-stops))`,
  ['gradient-to-l']: `linear-gradient(to left, var(--tw-gradient-stops))`,
  ['gradient-to-tl']: `linear-gradient(to top left, var(--tw-gradient-stops))`,
  ['gradient-gallery']: `linear-gradient(60deg, #000 2%, rgba(0, 0, 0, 0.00) 50%)`
} satisfies ThemeConfig['backgroundImage']

export { backgroundImage }
