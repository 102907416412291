import ArrowDown from './arrow-down.svg'
import BrandBadge from './brand-badge.svg'
import BrandIcon from './brand-icon.svg'
import BrandZenite from './brand-zenite.svg'
import Brand from './brand.svg'
import Camera from './camera.svg'
import ChevronDown from './chevron-down.svg'
import ChevronLeft from './chevron-left.svg'
import ChevronRight from './chevron-right.svg'
import ChevronUp from './chevron-up.svg'
import Close from './close.svg'
import Download from './download.svg'
import Fitness from './fitness.svg'
import Grill from './grill.svg'
import Isometric1 from './isometric-1.svg'
import Isometric2 from './isometric-2.svg'
import Isometric3 from './isometric-3.svg'
import Lamp from './lamp.svg'
import Mail from './mail.svg'
import MapPin from './map-pin.svg'
import Party from './party.svg'
import PhoneSolid from './phone-solid.svg'
import Phone from './phone.svg'
import Play from './play.svg'
import Security from './security.svg'
import Share from './share.svg'
import SocialFacebook from './social-facebook.svg'
import SocialInstagram from './social-instagram.svg'
import SocialLinkedin from './social-linkedin.svg'
import SocialTelegram from './social-telegram.svg'
import SocialThreads from './social-threads.svg'
import SocialTiktok from './social-tiktok.svg'
import SocialTwitter from './social-twitter.svg'
import SocialWhatsapp from './social-whatsapp.svg'
import SocialYoutube from './social-youtube.svg'
import Sofa from './sofa.svg'
import Spa from './spa.svg'
import View from './view.svg'

const svg = {
  'arrow-down': ArrowDown,
  'brand-badge': BrandBadge,
  'brand-icon': BrandIcon,
  'brand-zenite': BrandZenite,
  'brand': Brand,
  'camera': Camera,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'close': Close,
  'download': Download,
  'fitness': Fitness,
  'grill': Grill,
  'isometric-1': Isometric1,
  'isometric-2': Isometric2,
  'isometric-3': Isometric3,
  'lamp': Lamp,
  'mail': Mail,
  'map-pin': MapPin,
  'party': Party,
  'phone-solid': PhoneSolid,
  'phone': Phone,
  'play': Play,
  'security': Security,
  'share': Share,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-telegram': SocialTelegram,
  'social-threads': SocialThreads,
  'social-tiktok': SocialTiktok,
  'social-twitter': SocialTwitter,
  'social-whatsapp': SocialWhatsapp,
  'social-youtube': SocialYoutube,
  'sofa': Sofa,
  'spa': Spa,
  'view': View,
}

export type SvgName = keyof typeof svg

export { svg }